<template>
    <div>
        <ProductsNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter main fill">
            <h1>Conforming / Seismic Curbs</h1>

            <img :src="`${$cdiVars.BASE_URL}/images/v3/conforming_curb.jpg`" class="img-responsive img_content_right">

            <p>
                To help you find the product to successfully bid your project we put together a brief list of terms that will hopefully answer your questions.
            </p>

            <h3>Conforming/Seismic Roof Curb</h3>
            <p>
                Any roof curb, rigid or vibration, that have been engineered and stamped to meet current IBC 2015 (you may also hear this as complying or calculated).
            </p>

            <h3>IBC</h3>
            <p>
                International Building Code.
            </p>

            <h3>Rigid Mounted</h3>
            <p>
                Curbs solidly braced or bolted directly to the building structure without vibration isolation. Vibration Isolated Allows flexible motion between equipment and the building structure with the use of vibration isolators (springs) used to separate equipment from the building structure.
            </p>

            <h2>And for the engineers in the room</h2>

            <h3>Calculation terms</h3>

            <h4>Allowable load</h4>
            <p>
                Maximum design load traveling through the load path to challenge anchors.
            </p>

            <h4>Anchor</h4>
            <p>
                A device for connecting equipment to the building structure.
            </p>

            <h4>Attachments</h4>
            <p>
                Anchoring system/systems used to connect equipment to the building structure.
            </p>

            <h4>Base Material</h4>
            <p>
                What the anchor attachments are installed into.
            </p>

            <h4>Building Structure</h4>
            <p>
                Steel, concrete, wood members or surfaces that transfer the weight of the building and equipment to the ground.
            </p>

            <h4>Bumpers</h4>
            <p>
                Angles or other steel shapes rigidly mounted to the building structure in a pattern around the equipment base to limit horizontal movement.
            </p>

            <h4>Differential Movement</h4>
            <p>
                The movement between two objects or surfaces.
            </p>

            <h4>Dynamic Load</h4>
            <p>
                A load with varying magnitude over time.
            </p>

            <h4>Edge Distance</h4>
            <p>
                The distance between an anchor and the edge of the base material.
            </p>

            <h4>Embedment</h4>
            <p>
                How far a post-installed anchor is inserted into a hole after the anchor is set in place.
            </p>

            <h4>Flexible Mounted Equipment</h4>
            <p>
                Equipment supported on or from a vibration isolator.
            </p>

            <h4>Housed Spring</h4>
            <p>
                A spring isolator with steel guides usually separated by an elastomeric sheet located on two opposite sides of the spring (attached).
            </p>

            <h4>Load Transfer Angles</h4>
            <p>
                Angles bolted to equipment and to the building structure, transferring the weight and earthquake load through the angles to the building structure.
            </p>

            <h4>Manufactured Isolation Curb</h4>
            <p>
                A factory built curb designed to attached equipment to a roof and containing vibration isolators, which allow for slight movement of the equipment.
            </p>

            <h4>Post Installed Anchor</h4>
            <p>
                Anchors that are installed after the building structure is complete.
            </p>

            <h4>Pre-Manufactured Curb</h4>
            <p>
                A sheet metal curb manufactured at a factory and sent to the job site.
            </p>

            <h4>Restrained Spring</h4>
            <p>
                A vibration isolator containing a spring enclosed in a welded or bolted steel housing that limits the movement of the equipment attached. (use this when referring to the restraint of equipment movement with isolation curb, attached).
            </p>

            <h4>Conforming/Seismic restraint device</h4>
            <p>
                An attachment device designed to help equipment withstand an earthquake.
            </p>

            <h4>Shear Load</h4>
            <p>
                Load applied perpendicular to the axis of an anchor.
            </p>

            <h4>Static Load</h4>
            <p>
                A load that does not change over time.
            </p>
        </main>
    </div>
</template>

<script>
import ProductsNav from '@/components/content/ProductsNav.vue'
export default {
    name: "Conforming",
    components: {
        ProductsNav,
    },
    created() {
        this.$appStore.setTitle(['Conforming/Seismic Curbs 101']);
    },
}
</script>

<style scoped>

</style>